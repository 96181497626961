import { graphql, navigate } from "gatsby"
import React from "react"
import Container from "../components/Container"
import CourseOptionsEditor from "../components/user/CourseOptionsEditor"
import LoginStateContext, {
  withLoginStateContext,
} from "../contexes/LoginStateContext"
import Layout from "../templates/Layout"

class MissingInfo extends React.Component {
  static contextType = LoginStateContext

  onStepComplete = () => {
    if (typeof window !== "undefined") {
      window.history.back()
      return
    }
    navigate("/")
  }

  render() {
    if (!this.context.loggedIn) {
      if (typeof window !== "undefined") {
        navigate("/sign-in")
      }
      return <div>Redirecting...</div>
    }
    return (
      <Layout>
        <Container>
          <CourseOptionsEditor
            courseVariant="nodl"
            onComplete={this.onStepComplete}
          />
        </Container>
      </Layout>
    )
  }
}

export default withLoginStateContext(MissingInfo)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
